export default [
    {
        title: 'PQRSForm',
        load: () => import('@/modules/CustomerService/Pqrs/PqrsForm.vue'),
    },
    {
        title: 'ContactForm',
        load: () => import('@/modules/CustomerService/Contact/ContactForm.vue'),
    },
];
